import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { notify } from 'react-notify-toast';
import { useGetCategories, useUserViewer } from '../../../hooks';
import { handleGraphQLErrors } from '../../../lib/utils';
import {
  LATEST_APPLICATION_FORMDATA,
  SAVE_CUSTOM_FORM,
} from '../../LoanApplication/queries';
import { ApplicationContext } from '../ApplicationContext';
import constants from '../../../lib/constants';
import { UPLOAD_IMAGE } from '../../../hooks/useImageUploader';
import { UPLOAD_SUPPORTING_DOCUMENT } from '../../../hooks/useDocumentUploader';
import { NEW_LOAN_ROUTES } from '../../LoanApplication/routes';

const FILE_TYPES = {
  WORK_ID: 'WORKID',
  SELFIE: 'PASSPORT',
  GOVERNMENT_ID: 'GOVERNMENTID',
  DOCUMENT: 'DOCUMENT'
};

export const useCustomForm = () => {
  const [, , applicationState, , categoryId ] = useContext(ApplicationContext);
  const { viewerData, loading: userLoading } = useUserViewer();
  const user = viewerData?.loaded?.viewer?.me;
  const account = applicationState?.data?.application.account;
  const applicationId = applicationState?.data?.application.id;
  const applicationNumber =
    applicationState?.data?.application.applicationNumber;
  const customApplicationForm =
    applicationState?.data?.application.customApplicationForm;
  const { status, duration } = constants;
  const { loanCategories, loading } = useGetCategories();
  const history = useHistory();

  const showErrorAlert = (text, d = duration.SHORT) => {
    notify.show(text, status.ERROR, d);
  };
  const [formIndex, setFormIndex] = useState(0);
  const [customPath, setCustomPath] = useState(0);
  const { products } =
    loanCategories.find(category => category.id === categoryId) || {};

  const [defaultFormValues, setDefaultFormValues] = useState(
    customApplicationForm
      ? JSON.parse(JSON.stringify(customApplicationForm))
      : null,
  );

  useEffect(() => {
    if (customApplicationForm) {
      const { step, path } = customApplicationForm;
      setCustomPath(path);
      if (step) {
        setFormIndex(step);
      }
    }
  }, [customApplicationForm]);

  const [applicationForm, setApplicationForm] = useState([]);

  const handleNext = () => {
    setFormIndex(formIndex + 1);
  };

  const [mutate, { loading: saveFormLoading }] = useMutation(SAVE_CUSTOM_FORM, {
    onCompleted({ saveCustomApplicationForm }) {
      if (saveCustomApplicationForm && saveCustomApplicationForm.success) {
        const { path, step } = saveCustomApplicationForm.data || {};
        const activeFormTabs = applicationForm?.filter((tab) => !tab.linkedToOption)

        if (path) {
          setCustomPath(saveCustomApplicationForm.data?.path);
          return;
        }
        if (step < activeFormTabs?.length && !path) {
          setCustomPath('');
          handleNext();
        }  else {
          history.replace(`/application-custom/${applicationNumber}/${NEW_LOAN_ROUTES.addCard}`);
        }  
      }
    },
    onError(error) {
      const errorMessage = handleGraphQLErrors(error);
      showErrorAlert(
        errorMessage || 'There was an error saving data please try again later',
      );
    },
  });

  const [getLatestForm, { loading: getLatestLoading }] = useLazyQuery(
    LATEST_APPLICATION_FORMDATA,
    {
      onError(error) {
        showErrorAlert(handleGraphQLErrors(error));
      },
      onCompleted(data) {
        if (data?.latestApplicationFormData?.data && !defaultFormValues) {
          setDefaultFormValues(
            JSON.parse(JSON.stringify(data?.latestApplicationFormData?.data)),
          );
          setFormIndex(formIndex);
        }
      },
    },
  );

  useEffect(() => {
    if (!loading) {
      setApplicationForm(products?.[0]?.applicationForm?.filter(form =>!form?.linkedToOption));
    }
  }, [loading, products]);

  useEffect(() => {
    if (!userLoading && account?.name) {
      getLatestForm({ variables: { customerId: account.name } });
    }
  }, [userLoading, account?.name]);

  const [mutateImage, { loading: uploadImageLoading }] = useMutation(UPLOAD_IMAGE, {
    onError(error) {
      showErrorAlert(handleGraphQLErrors(error) || 'Error uploading image');
    },
  });

  const [mutateFile, { loading: uploadDocumentLoading }] = useMutation(UPLOAD_SUPPORTING_DOCUMENT, {
    onError(error) {
      showErrorAlert(handleGraphQLErrors(error) || 'Error uploading document');
    },
  });

  const uploadFile = useCallback(async (fileDetails, options) => {
    const { type, name, documentName, documentNumber } = options;
    const { file, validity } = fileDetails;
    
    if (!validity) {
      throw new Error('Invalid file');
    }
    
    const { firstName, lastName, id } = user || {};
    let fileName;
    let variables;
    
    const isImage = file?.type?.startsWith('image/');
    
    switch (type) {
      case FILE_TYPES.WORK_ID:
        fileName = `work_id_${firstName}_${lastName}_${id}`;
        variables = { image: file, fileName, type: FILE_TYPES.WORK_ID };
        break;
      case FILE_TYPES.SELFIE:
        fileName = name === 'selfie' 
          ? `selfie_${firstName}_${lastName}_${id}`
          : `image_${firstName}_${lastName}_${id}`;
        variables = { image: file, fileName, type: FILE_TYPES.SELFIE };
        break;
      case FILE_TYPES.GOVERNMENT_ID:
        fileName = `${documentName}_${documentNumber}_${firstName}_${lastName}_${id}`;
        variables = { image: file, fileName, type: FILE_TYPES.GOVERNMENT_ID };
        break;
      case FILE_TYPES.DOCUMENT:
        fileName = `${name}_${firstName}_${lastName}_${id}`;
        // If it's an image file but in a document field, use the image mutation
        if (isImage) {
          variables = { image: file, fileName, type: FILE_TYPES.SELFIE };
        } else {
          variables = { file, documentName: fileName, userId: id };
        }
        break;
      default:
        fileName = `${name}_${firstName}_${lastName}_${id}`;
        variables = { file, documentName: fileName, userId: id };
        break;
    }
    
    try {
      // Use appropriate mutation based on file type and mime type
      if ([FILE_TYPES.WORK_ID, FILE_TYPES.SELFIE, FILE_TYPES.GOVERNMENT_ID].includes(type) || 
          (type === FILE_TYPES.DOCUMENT && isImage)) {
        const { data } = await mutateImage({ variables });
        const { fileUrl, key, bucket } = data?.uploadImageAndSaveToUserMetaData || {};
        return { url: fileUrl, key, bucket };
      } else {
        const { data } = await mutateFile({ variables });
        return { 
          url: data?.uploadSupportingDocument?.file?.url, 
          key: data?.uploadSupportingDocument?.file?.key, 
          bucket: data?.uploadSupportingDocument?.file?.bucket 
        };
      }
    } catch (error) {
      showErrorAlert(`Failed to upload ${name}: ${error?.message}`);
      throw error;
    }
  }, [user, mutateImage, mutateFile]);

  const processFile = useCallback(async (file) => {
    const { name, fileDetails, documentName, documentNumber } = file;
    
    let type;
    // Determine file type based on name and mime type
    if (name === 'workId') {
      type = FILE_TYPES.WORK_ID;
    } else if (name === 'selfie') {
      type = FILE_TYPES.SELFIE;
    } else if (name === 'governmentId') {
      type = FILE_TYPES.GOVERNMENT_ID;
    } else {
      // For other files, check if it's an image or document
      type = fileDetails?.file?.type?.startsWith('image/') 
        ? (name === 'image' ? FILE_TYPES.SELFIE : FILE_TYPES.DOCUMENT)
        : FILE_TYPES.DOCUMENT;
    }
    
    const uploadResult = await uploadFile(fileDetails, { 
      type, 
      name, 
      documentName, 
      documentNumber 
    });
    
    // Validate that we have required fields in the upload result
    if (!uploadResult || !uploadResult?.key || !uploadResult?.url) {
      throw new Error(`Upload for ${name} failed or returned incomplete data`);
    }
    
    // Format the result based on file type
    if (name === 'governmentId') {
      return {
        name,
        fileDetails: {
          ...uploadResult,
          documentName,
          documentNumber,
        },
      };
    } else {
      return {
        name,
        fileDetails: uploadResult,
      };
    }
  }, [uploadFile]);

  // Extract form data without files
  const extractFormDataWithoutFiles = useCallback((formData, formName) => {
    const keySet = new Set(formData[formName]?.files?.map(item => item?.name));
    
    return Object.keys(formData[formName])
      .filter(key => !keySet.has(key))
      .reduce((result, key) => {
        result[key] = formData[formName][key];
        return result;
      }, {});
  }, []);

  const handleSubmit = useCallback(async (formData) => {
    const { name } = applicationForm[formIndex] || {};
    
    if (formData[name]?.files?.length > 0) {
      try {
        // Upload all files in parallel
        const uploadPromises = formData[name].files.map(file => processFile(file));
        const uploadedFiles = await Promise.all(uploadPromises);
        
        // Verify that all uploads were successful and have required data
        const hasFailedUploads = uploadedFiles?.some(file => 
          !file?.fileDetails || 
          !file?.fileDetails?.key || 
          !file?.fileDetails?.url || 
          Object.keys(file?.fileDetails).length === 0
        );
        
        if (hasFailedUploads) {
          showErrorAlert('One or more file uploads failed. Please try again.');
          return; // Exit without submitting the form
        }
        
        const formDataWithoutFiles = extractFormDataWithoutFiles(formData, name);
        formData[name] = {
          ...formDataWithoutFiles,
          files: uploadedFiles
        };
      } catch (uploadError) {
        showErrorAlert('File upload failed: ' + uploadError?.message);
        // Return early without submitting the form if uploads fail
        return;
      }
    }
    
    // Only proceed with form submission if all uploads were successful
    try {
      await mutate({
        variables: {
          applicationId,
          data: { ...formData, step: formIndex + 1, path: '' },
        },
      });
    } catch (error) {
      showErrorAlert('Error processing form submission: ' + error?.message);
    }
  }, [applicationForm, formIndex, mutate, applicationId, processFile, extractFormDataWithoutFiles]);
  return {
    applicationForm,
    handleNext,
    formIndex,
    applicationId,
    applicationNumber,
    customApplicationForm,
    account,
    handleSubmit,
    saveFormLoading,
    loading,
    defaultFormValues,
    getLatestLoading,
    mutate,
    customPath,
    uploadDocumentLoading,
    uploadImageLoading,
    user,
  };
};
