import { useState, useCallback, useEffect } from 'react';
import { bytesToSize, handleGraphQLErrors } from '../../../lib/utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { UPLOAD_BANK_STATEMENT } from '../mutations';
import { pages } from '../../../lib/constants';
import { GET_DECIDE_JOB_STATUS } from '../queries';
import { useBanks } from '../BankAccount/useBankAccount';
import { NEW_LOAN_ROUTES } from '../routes';
import { useHistory } from 'react-router-dom';

// Constants
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 1MB
const ALLOWED_FILE_TYPES = ['application/pdf'];
const POLLING_INTERVAL = 15000; // 15 seconds
const MAX_POLLING_DURATION = 90000; // 90 seconds
const ERROR_DISPLAY_DURATION = 180000; // 3 minutes

// Job status constants
const JOB_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED',
  SKIPPED: 'SKIPPED'
};

const useBankStatementUploaderForm = ({ customForm }) => {
  const [uploadBankStatementError, setUploadBankStatementError] = useState(null);
  const [pollingPage, setPollingPage] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [bankStatementDetails, setBankStatementDetails] = useState({});
  
  const {
    loanApplicationState,
    setLoanApplicationState,
    updateContextState,
    createApplicationTrace,
    application,
    applicationLoading,
    applicationNumber,
    setCompletedStep,
    account,
    userLoading,
    getUserViewer,
    clientInfo: { allowBankSelectionOnPdfUpload, externalBankStatementTenor }
  } = useLoanApplicationContext();
  
  const history = useHistory();
  const { banks, loading: banksLoading } = useBanks({ filtered: false });

  const hasUploaded = application?.application?.completedSteps?.includes("UPLOAD_BANK_STATEMENT");
  const [uploadCompleted, setUploadCompleted] = useState(hasUploaded);
  const applicationBank = application?.application?.bankAccount || account?.bankAccounts[0];
  const getPath = path => `/application-custom/${applicationNumber}/${path}`;

  const [mutate, { data, loading: uploadBankStatementLoading }] = useMutation(
    UPLOAD_BANK_STATEMENT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setUploadBankStatementError(
          errorMessage ||
            'Unable to upload file. Check file format and try again later.',
        );
      }
    },
  );

  const [getDecideJobStatus, { 
    loading: decideJobStatusLoading, 
    data: decideJobStatusData, 
    startPolling, 
    stopPolling 
  }] = useLazyQuery(GET_DECIDE_JOB_STATUS, {
    variables: { input: { applicationId: application?.application?.id } },
    skip: !application?.application?.id,
    fetchPolicy: 'no-cache',
  });

  // Validate file before upload
  const validateFile = useCallback((file) => {
    if (!file) {
      throw new Error('Please select a pdf file for upload');
    }

    if (file?.size > MAX_FILE_SIZE) {
      throw new Error('File size must be lower than 10MB');
    }

    if (!ALLOWED_FILE_TYPES.includes(file?.type)) {
      throw new Error('Only .pdf files are allowed. Please check the file format and try again.');
    }

    return true;
  }, []);

  // Handle file selection
  const handleDocumentPreview = useCallback(e => {
    const { validity, files } = e.target;
    const file = files[0];

    try {
      if (file) {
        validateFile(file);
        setUploadBankStatementError('');
        setBankStatementDetails({
          fileName: file.name,
          fileSize: bytesToSize(file.size),
          bankStatement: { file, validity: validity?.valid },
        });
      }
    } catch (error) {
      setUploadBankStatementError(error?.message);
      setBankStatementDetails({});
    }
  }, [validateFile]);

  const getUploadingStatus = useCallback(async () => {
    try {
      const { data } = await getDecideJobStatus();
      
      if (!data || !data.getDecidePdfStatus) {
        throw new Error('Unable to get processing status');
      }
      
      const { bankStatementId, message, status } = data.getDecidePdfStatus;

      if (status === JOB_STATUS.IN_PROGRESS) {
        setPollingPage(true);
        setIsPolling(true);
      }

      if (status === JOB_STATUS.FAILED) {
        setPollingPage(false);
        setUploadBankStatementError(message || 'Processing failed. Please try again.');
      }

      return {
        bankStatementId,
        message,
        status
      };
    } catch (error) {
      setUploadBankStatementError('Error checking processing status: ' + error?.message);
      setPollingPage(false);
      return { status: JOB_STATUS.FAILED };
    }
  }, [getDecideJobStatus]);

  const uploadBankStatement = useCallback(
    async (bankStatement, bankId, documentPassword, applicationId) => {
      try {
        if (!bankStatement) {
          throw new Error('Please select a pdf file for upload');
        }

        const { validity, file } = bankStatement;
        
        if (!validity) {
          throw new Error('Invalid file');
        }
        
        validateFile(file);
        
        await mutate({
          variables: {
            file,
            password: documentPassword,
            bankId,
            applicationId,
          },
        });
        
        setUploadCompleted(true);
        setPollingPage(true);
        setIsPolling(true);
        getUploadingStatus();
        
      } catch (error) {
        setUploadBankStatementError(error?.message || 'Upload failed. Please try again.');
        setPollingPage(false);
        setIsPolling(false);
      }
    },
    [mutate, getUploadingStatus, validateFile],
  );

  const handleUpload = useCallback(
    ({ statementPassword, bankId = applicationBank?.bank?.id }) => {
      logEvent('Signup', 'Upload Bank Statement');
      
      try {
        if (!bankStatementDetails?.bankStatement) {
          throw new Error('Please select a file to upload');
        }
        
        if (!application?.application?.id) {
          throw new Error('Application ID is missing');
        }
        
        const selectedBankId = bankId?.length > 0 ? bankId : applicationBank?.bank?.id;
        
        if (!selectedBankId) {
          throw new Error('Please select a bank');
        }
        
        uploadBankStatement(
          bankStatementDetails?.bankStatement,
          selectedBankId,
          statementPassword,
          application?.application?.id,
        );
      } catch (error) {
        setUploadBankStatementError(error?.message);
      }
    },
    [bankStatementDetails, applicationBank, application, uploadBankStatement],
  );

  const stopPdfJobPolling = useCallback(() => {
    stopPolling();
    setCompletedStep('bankStatementUpload');
    if (customForm) {
      history.push(getPath(NEW_LOAN_ROUTES.confirmLoan));
    }
  }, [stopPolling, setCompletedStep, customForm, history, getPath]);

  useEffect(() => {
    let errorTimeout;
    if (uploadBankStatementError) {
      errorTimeout = setTimeout(() => setUploadBankStatementError(''), ERROR_DISPLAY_DURATION);
    }
    return () => clearTimeout(errorTimeout);
  }, [uploadBankStatementError]);

  // Update application state when data is received
  useEffect(() => {
    if (data?.uploadBankStatement?.filename) {
      setLoanApplicationState(prevState => ({
        ...prevState,
        bankStatementFilename: data.uploadBankStatement.filename,
      }));
    }
  }, [data, setLoanApplicationState]);

  useEffect(() => {
    getUserViewer();
    const { isFromOkraPage } = loanApplicationState;

    updateContextState('upload-bank-statement', {
      applicationId: application?.application?.id,
      bankId: applicationBank?.bank?.id,
      isFromOkraPage,
    });
  }, []);

  useEffect(() => {
    createApplicationTrace(
      pages.bankStatementUpload,
      'Navigated to Bank Statement Upload Screen',
    );
  }, []);
  

  useEffect(() => {
    let isMounted = true;
    let pollingTimeout;
  
    const startPdfJobPolling = async () => {
      if (pollingPage) {
        startPolling(POLLING_INTERVAL);
        pollingTimeout = setTimeout(() => {
          if (isMounted) {
            setIsPolling(false);
            stopPdfJobPolling();
          }
        }, MAX_POLLING_DURATION);
      }
    };
  
    startPdfJobPolling();
  
    return () => {
      isMounted = false;
      clearTimeout(pollingTimeout);
    };
  }, [pollingPage, startPolling, stopPdfJobPolling]);
  

  useEffect(() => {
    if (isPolling && decideJobStatusData && uploadCompleted) {
      const status = decideJobStatusData?.getDecidePdfStatus?.status;
      
      switch (status) {
        case JOB_STATUS.DONE:
        case JOB_STATUS.SKIPPED:
          stopPdfJobPolling();
          break;
        case JOB_STATUS.FAILED:
          setUploadBankStatementError(
            decideJobStatusData?.getDecidePdfStatus?.message || 
            'Processing failed. Please re-upload'
          );
          stopPolling();
          setPollingPage(false);
          break;
        default:
          break;
      }
    }
    
    // Handle case where polling stops but job is still in progress
    if (!isPolling && decideJobStatusData?.getDecidePdfStatus?.status === JOB_STATUS.IN_PROGRESS) {
      stopPdfJobPolling();
    }
  }, [isPolling, decideJobStatusData, stopPolling, stopPdfJobPolling, uploadCompleted]);

  return {
    applicationLoading,
    uploadBankStatementLoading,
    uploadBankStatementError,
    handleUpload,
    handleDocumentPreview,
    bankStatementDetails,
    userLoading,
    getUploadingStatus,
    decideJobStatusLoading,
    pollingPage,
    applicationBank,
    banks,
    banksLoading,
    allowBankSelectionOnPdfUpload,
    externalBankStatementTenor,
  };
};

export default useBankStatementUploaderForm;
